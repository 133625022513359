<template>
  <v-container fluid>
    <ClientMap :mapData="stores" />
  </v-container>
</template>

<script>
import { webserver,getToday } from "../services/webserver.js";
import ClientMap from "../components/trackMap.vue";
export default {
  components: { ClientMap },
  data() {
    return {
      errors: [],
      loading_status: false,
      stores: [],
    };
  },
  mounted() {
    this.get_data();
  },
  methods: {
    get_clients() {
      this.loading_status = true;
      var qry = {
        account: this.$store.state.profile.account,
        table: "clients",
      };
      webserver("get_table", qry, (data) => {
        this.clients = data;
      });
    },
    get_data() {
      this.items_bygroup = null;
      var qry = {
        account: this.$store.state.profile.account,
        table: "tracking",
        filters: [
          {
            field: "location",
            operator: "<>",
            value: "",
          },
          {
            field: "date(issue_date)",
            operator: "=",
            value: getToday(),
          },
        ],
      };
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        //console.log(data);
        data.forEach((rg) => {
           var lct = JSON.parse(rg.location);
           //console.log(lct);
          var posi = '{"lat":' + lct[0]+ ',"lng":' +  lct[1] + '}';
           rg.name=rg.user_name;
           rg.address="";
           rg.mobile="";
           rg.location = posi;
          
        });
       // console.log(data);
        // data.sort(function (a, b) {
        //   return parseFloat(a.name) - parseFloat(b.name);
        // });
        this.stores = data;
      });
    },
  },
};
</script>


